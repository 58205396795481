import React, { useEffect, useRef, useState } from 'react'

import {
  axisBottom,
  axisLeft,
  ScaleBand,
  scaleBand,
  ScaleLinear,
  scaleLinear,
  select,
  selectAll
} from "d3";

import type { IData } from "./types";

interface BarChartProps {
  data: IData[];
}

interface AxisBottomProps {
  scale: ScaleBand<string>;
  transform: string;
}

interface AxisLeftProps {
  scale: ScaleLinear<number, number, never>;
}

interface BarsProps {
  data: BarChartProps["data"];
  height: number;
  scaleX: AxisBottomProps["scale"];
  scaleY: AxisLeftProps["scale"];
  title: any;
}

function AxisBottom({ scale, transform }: AxisBottomProps) {
  const ref = useRef<SVGGElement>(null);

  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(axisBottom(scale));
    }
    select(".domain").remove();
    selectAll(".tick line").attr("y2", 0);
  }, [scale]);

  return <g ref={ref} transform={transform} />
    ;
}

function AxisLeft({ scale }: AxisLeftProps) {
  const ref = useRef<SVGGElement>(null);

  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(axisLeft(scale));
    }
  }, [scale]);

  return;
}

function Bars({ data, height, scaleX, scaleY }: BarsProps) {


  return (
    <>
      {data.map(({ value, label }) => (


        <rect
          key={`bar-${label}`}
          x={scaleX(label)}
          y={scaleY(value)}
          width={scaleX.bandwidth()}
          height={height - scaleY(value)}
          fill="hsl(10, 79%, 65%)"
          rx="2"
          className="bar"
        > <title>${value}</title></rect>
      ))}
    </>
  );
}

export function BarChart({ data }: BarChartProps) {
  const margin = { top: 10, right: 0, bottom: 20, left: 0 };
  const width = 270 - margin.left - margin.right;
  const height = 120 - margin.top - margin.bottom;

  const scaleX = scaleBand()
    .domain(data.map(({ label }) => label))
    .range([0, width])
    .padding(0.3);

  const scaleY = scaleLinear()
    .domain([0, Math.max(...data.map(({ value }) => value))])
    .range([height, 0]);

  const xdim = 270
  const ydim = 120






  return (
    <div className="my_dataviz" id="my_dataviz">
      <svg id="circleBasicTooltip"
        viewBox={`0 0 ${xdim + margin.left + margin.right} ${ydim + margin.top + margin.bottom
          }`}
        preserveAspectRatio="xMinYMin"
        width="100%"
        height="100%"
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <AxisBottom scale={scaleX} transform={`translate(0, ${height})`} />
          {/* <AxisLeft scale={scaleY} /> */}
          <Bars data={data} height={height} scaleX={scaleX} scaleY={scaleY} />
        </g>
      </svg>
    </div>);
}
