import React from 'react'
import type { IData } from "../..types";
import { BarChart } from "../../BarChart";


const BAR_CHART_DATA: IData[] = [
  { label: "Mon", value: 100 },
  { label: "Tue", value: 200 },
  { label: "Wed", value: 50 },
  { label: "Thu", value: 150 },
  { label: "Fri", value: 150 },
  { label: "Sat", value: 150 },
  { label: "Sun", value: 150 }
];

 function BarCharta() {

  return (
    <>
      <div style={{ backgroundColor: "hsl(27, 66%, 92%)", position:"absolute", top:"0", zIndex:"999", left:"0",width:"100%",height:"100%",bottom:"0"}}>

        <section className="challenge" style={{ maxWidth: "340px",width:"100%", margin: "auto" }}>
          <div style={{ display: "flex", borderRadius: "5.5px", color: "white", padding: "0.6rem", height: "6rem", margin: "5rem 0 1.3rem 0", backgroundColor: "hsl(10, 79%, 65%)", flexDirection: "row", justifyContent: "space-between", alignItems:"center"}}>
            <div  style={{ display: "flex", flexDirection: "column",  justifyContent: "space-around" }}>
              <div style={{color:"whitesmoke",fontSize:"0.9rem"}}>My balance</div>
              <div style={{marginTop:"0.5rem",fontWeight:"bolder",fontSize:"1.5rem"} }>$ 999.48</div>
            </div>
            <svg width="72" height="48" viewBox="0 0 72 48" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#382314" cx="48" cy="24" r="24" /><circle stroke="#FFF" stroke-width="2" cx="24" cy="24" r="23" /></g></svg>
          </div>
          <div style={{ display: "flex", borderRadius: "5.5px", backgroundColor: "white", flexDirection: "column" }}>
            <h3 style={{padding:"0.9rem",fontSize:"1.4rem"}}>Spending - Last 7 days</h3>
            <BarChart data={BAR_CHART_DATA} />
            <hr style={{ color: "", width:"calc(100% - 1rem)",margin:"0 auto 1rem auto 0" }} />
         
            <div style={{ display: "flex",padding:"0.9rem", flexDirection: "row", justifyContent: "space-between" }}>

              <div>


                <div  style={{color:"#666",fontSize:"0.9rem"}}>Total this month</div>
                <div style={{color:"hsl(25, 47%, 15%)",fontSize:"2rem",fontWeight:"bolder"}}>$478.48</div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignContent:"flex-end",justifyContent: "space-around" }}>
                <div style={{color:"hsl(25, 47%, 15%)", alignSelf:"flex-end" ,fontWeight:"bolder"}}>+24%</div>
                <div style={{color:"#666",fontSize:"0.9rem"}}>From last month</div>
              </div>
            </div> </div>
        </section>
      </div>
    </>
  );

}


export default BarCharta;